@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width:320px)  { /* smartphones, iPhone, portrait 480x320 phones */ }
@media (min-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ }
@media (min-width:641px)  { /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */ }
@media (min-width:961px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }
/* ==========
  Variables
========== */

:root {
  /* Colors */
  --white: #FFFFFF;
  --offwhite: #F8F7F2;
  --gray: #CED7D6;
  --black: #000000;
  --orange: #FD5200;
  --blue: #9FAEB0;
  
  /* Desktop Sizes */
  --heading-large: 5.6rem;
  --heading-medium: 3.6rem;
  --heading-small: 2.4rem;
  --paragraph: 1.11rem;
  --navbar-buttons: 2.4rem;
  --margin-width: 55px;

  /* Mobile Sizes */
  --mobile-heading-large: 2.6rem;
  --mobile-heading-medium: 2rem;
  --mobile-heading-small: 2.4rem;
  --mobile-paragraph: 1.11rem;
  --mobile-navbar-buttons: 2.4rem;
  --mobile-margin-width: 25px;

  /* misc */
  --transition-main: .175, .685, .32;

  /* Fonts */
  --font-main: "Open Sans";
}

/* ======
  Fonts
====== */

/* ===============
  Global Styles
=============== */

*, *::before, *::after {
  box-sizing: inherit;
}
html, body {
  margin: 0;
  width: 100%;
  max-width: 100vw;
  max-height: 100vw;
  font-family: "Open Sans";
  font-family: var(--font-main);
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overscroll-behavior-y: none;
}

/* ============
  Typography
============ */

/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
/* Font Size */
h1 {
  font-size: 5.6rem;
  font-size: var(--heading-large);
}
h2 {
  font-size: 3.6rem;
  font-size: var(--heading-medium);
}
h3 {
  font-size: 2.4rem;
  font-size: var(--heading-small);
}
h4 {
  font-size: calc(2.4rem - .2rem);
  font-size: calc(var(--heading-small) - .2rem);
}
h5 {
  font-size: calc(2.4rem - .4rem);
  font-size: calc(var(--heading-small) - .4rem);
}
h6 {
  font-size: calc(2.4rem - .6rem);
  font-size: calc(var(--heading-small) - .6rem);
}
@media (max-width:481px)  { 
  h1 {
    font-size: 2.6rem;
    font-size: var(--mobile-heading-large);
  }
  h2 {
    font-size: 2rem;
    font-size: var(--mobile-heading-medium);
  }
  h3 {
    font-size: 2.4rem;
    font-size: var(--mobile-heading-small);
  }
  h4 {
    font-size: calc(2.4rem - .2rem);
    font-size: calc(var(--mobile-heading-small) - .2rem);
  }
  h5 {
    font-size: calc(2.4rem - .4rem);
    font-size: calc(var(--mobile-heading-small) - .4rem);
  }
  h6 {
    font-size: calc(2.4rem - .6rem);
    font-size: calc(var(--mobile-heading-small) - .6rem);
  }
}

/* Font Weight */
h1, h2 {
  font-weight: 900;
}
h3, h4, h5, h6 {
  font-weight: 800;
}
/* Paragraphs */
p {
  margin: 0;
  font-size: 1.11rem;
  font-size: var(--paragraph);
}
/* Lists */
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
/* Active link */
.isActive {
  color: #000000 !important;
  color: var(--black) !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

